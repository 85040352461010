import React, { useState, useEffect, useContext } from "react";
import Footer from "../../../components/Footer";
import Header from "../../../components/Header";
import ZodiacDD from "../../../components/ZodiacDD";
import banner from "../../../staticAssets/Horoscope Bg.png";
import logo from "../../../staticAssets/Leo.png";
import axios from "axios";
import { navigate } from "gatsby";
import { AuthContext } from "../../../context/Auth";
import { Helmet } from "react-helmet-async";
import { AnalyticsContext } from "../../../context/Analytics";

function Index({location}) {
  const [blogs, setblogs] = useState([]);
const { gtmPageViewEvent } = useContext(AnalyticsContext) || {
    gtmPageViewEvent: () => {},
  };
  const { user } = useContext(AuthContext) || {
    user: {},
  };
  useEffect(() => {
    const fn = async () => {
      let res = await axios.get(
        "https://www.bodhi.app/blog/wp-json/wp/v2/posts"
      );

      if (res.status == 200) {
        setblogs([...res.data]);
      }
    };
    gtmPageViewEvent(window.location.pathname, window.location.host);
fn();
  }, []);
  return (
    <div className="h-screen flex flex-col block hide-scroll-bar scrollbar-hide worksans w-screen overflow-x-clip">
      <Header />
      <Helmet>
        <link
          rel="canonical"
          href={"https://www.bodhi.app"+location?.pathname}
        />
      </Helmet>
      <div className="flex-1 overflow-y-auto flex flex-col">
        <main className="grow hide-scroll-bar scrollbar-hide">
          <div className=" w-full relative bg-[#FFEEEF] overflow-hidden">
            <img className="h-32 md:h-auto w-full object-fill" src={banner} />
            <div className="absolute top-0 w-full h-full flex justify-center items-center">
              <h1 className="text-center text-2xl md:text-4xl font-semibold text-white">
                Gemini Traits
              </h1>
            </div>
          </div>
          <div className="container mx-auto flex flex-col">
            <div className="flex flex-wrap my-2 ml-2">
              <a
                href="https://www.bodhi.app/"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm">Bodhi</h4>
              </a>
              
              <h4 className="mx-2 text-sm">/</h4>
              <a
                href="https://www.bodhi.app/zodiac-signs/"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm">Zodiac Signs</h4>
              </a>
              <h4 className="mx-2 text-sm">/</h4>
              <a
                href="https://www.bodhi.app/zodiac-signs/gemini"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm">Gemini</h4>
              </a>
              
              <h4 className="mx-2 text-sm">/</h4>
              <h4 className="text-sm">Gemini Traits</h4>
            </div>
            <div className="flex mt-10 items-center">
              <div>
                <img className="h-24 md:h-48" src={logo} />
              </div>
              <div className="flex-1 flex flex-col md:flex-row">
                <div className=" flex-1 flex flex-col">

                <h1 className="font-semibold text-xl md:text-4xl ml-4">Gemini Traits</h1>
                <h2 className="text-sm md:text-base ml-4">May 21 - Jun 21</h2>
                </div>
                <div className="">
                <ZodiacDD name={"Change Sunsign"} />
              </div>
              </div>
              
            </div>
            <div className="grid grid-cols-3 md:grid-cols-4 gap-2 md:gap-8 mt-10 text-[0.8rem] md:text-base mx-1">
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2 py-1" onClick={() => {
                    navigate("/zodiac-signs/gemini/gemini-woman");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Gemini Woman</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>

              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2" onClick={() => {
                    navigate("/zodiac-signs/gemini/gemini-health");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Gemini Health</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2" onClick={() => {
                    navigate("/zodiac-signs/gemini/gemini-career");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Gemini Career</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/gemini/gemini-love");
                  }}>
                  <h5 className="font-semibold text-black">Gemini Love</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/gemini/gemini-nature");
                  }}>
                  <h5 className="font-semibold text-black">Gemini Nature</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/gemini/gemini-man");
                  }}>
                  <h5 className="font-semibold text-black">Gemini Man</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/gemini/gemini-relationship");
                  }}>
                  <h5 className="font-semibold text-black">Gemini Relationship</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/gemini/gemini-traits");
                  }}>
                  <h5 className="font-semibold text-black">Gemini Traits</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/gemini/gemini-facts");
                  }}>
                  <h5 className="font-semibold text-black">Gemini Facts</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
            </div>
            <span className="my-4 mx-1 md:mx-0">
              
            <p className="playfair text-black text-xl md:text-3xl">Gemini Traits</p><br/>
You are friendly, talkative, and love to be surrounded by exciting people. You have an outgoing personality that often gets at least a little social. You are adventurous and love to discover new things, especially when a new idea or novel inspires you. You are good at leading conversations, whether they are related to adventure conversation adventure or intellectual discussion. You are ruled by the planet Mercury which makes you active, humorous, and efficient while communicating with others.
 <br/><br/>
Gemini is no exception to this rule. It is a sign that is curious, talkative, and social. It is also a sign that she possesses a great sense of humor and is very caring.
 <br/><br/>
The good thing about being a Gemini is that you are friendly, communicative, and easy to get along. You are a great conversationalist and can keep people amused for hours. You are a great mimic, too, and can impersonate people easily.
 <br/><br/>
<p className="font-bold text-orange-500 inline-block mr-2">Here are some of the positive and negative Gemini Traits:</p>
 <br/><br/>
<p className="font-bold text-orange-500 inline-block mr-2">Take a look at some of the positive and negative traits of Gemini for complete astrology insights:</p>
 <br/><br/>
<p className="font-bold text-orange-500 inline-block mr-2">Positive Traits:</p> Flexible, outgoing, funny, enthusiastic, intelligent, versatile<br/><br/>
<p className="font-bold text-orange-500 inline-block mr-2">Negative Traits:</p> Superficial, backstabber, lack of direction, lousy decision-making skills, anxious
 <br/><br/>
 <p className="playfair text-black text-xl md:text-3xl">Gemini Positive Traits</p>
 <br></br>
 <p className="playfair text-black text-xl md:text-3xl">Flexible</p><br/>
You are adaptable, easygoing, and flexible by nature. You are always willing to try new things at least once in your life. You are ready to go along with any plans, and you usually don't hold back when speaking your mind. Your friends can always count on you for support, and you are a loyal friend to those you care about.
 <br/><br/>
You like to try new things. You are adaptable and open to new experiences. You are not hesitant to try new things, even when they are scary or unfamiliar. You are ready to go along with any plans, and you are open to learning more about the techniques as you go along.
 <br/><br/>
You are a very adaptable person and can fit in with most groups and situations. You can see things from many different angles and catch the best in people. Your close friends and family know that they can always count on you to have their back, even when you might disagree with what they are doing. You are known as the chameleons of the zodiac, constantly adapting to the situation and adjusting to the people around you.
 <br/><br/>
 
 <p className="playfair text-black text-xl md:text-3xl">Outgoing</p><br/>
You have a sound mind for learning, a curious nature, and a strong desire to explore the world around you. You value knowledge, education, and growth and constantly seek new experiences and hobbies to challenge yourself with. 
 <br/><br/>
You are continually seeking new experiences and learning new things and can often be found talking to others or learning something new. It's not unusual for you to be the party's life, or to have many friends, and being around you is never dull.
 <br/><br/>
 
 <p className="playfair text-black text-xl md:text-3xl">Funny</p><br/>
You are always ready with a smart-aleck remark and a cutting joke. Sometimes, your snarky comments and subtle put-downs are the only things that can get someone to stop and listen to what you are saying. You are good at reading people and know when to be serious and when to be funny. You are not afraid to be the center of attention when the situation calls for it. You're an absolute charmer.
 <br/><br/>
Your funny gigs will help others escape from a depressive and stressed state of mind. Gemini, you are naturally social, which means that you are the life of any party.
 <br/><br/>
 
 
 <p className="playfair text-black text-xl md:text-3xl">Enthusiastic</p><br/>
You are enthusiastic and full of life and always crave to accomplish new and exciting things. However, your enthusiasm is infectious at times. You are always a little ahead of others so that you can discover everything at least once in a lifetime. Your mind is always working fast, and share your thoughts with the people around you.
 <br/><br/>
 <p className="playfair text-black text-xl md:text-3xl">Smart</p><br/>
You are intelligent and have a thirst for learning. You are curious about the world and want to learn more. You like to read and gain as much knowledge as possible. You are an independent thinker and value your own opinion. You are good at languages, literature, and debate.
 <br/><br/>
You are a fast learner and tend to retain new information better than most. You are a sponge when it comes to knowledge and has a tendency to become bored very quickly.
You like to keep yourself informed and updated on all the latest developments in your field. You enjoy reading books, magazines, and other written material, and you value the opinions of others who have more experience and knowledge than you do. You have a passion for 
learning and are always looking for new ways to expand your knowledge and skills.
 <br/><br/>
 <p className="playfair text-black text-xl md:text-3xl">Versatile</p><br/>
You are the most versatile person. You can handle multiple things at a time and excel in each. You can juggle multiple projects and activities and are always ready to tackle the next thing. You have a natural ability to multi-task and find many ways to accomplish the same goal.<br/><br/>
Your mind is like a toolbox, filled with various tools that you can use to solve any problem. You tend to see things in a group of ways and can develop multiple solutions to a problem.
 <br/><br/>
 <p className="playfair text-black text-xl md:text-3xl">Gemini Negative Traits</p><br/>
 <br></br>
 <p className="playfair text-black text-xl md:text-3xl">Superficial</p><br/>
You're always curious to learn new things, and you're constantly exploring new interests and hobbies. But when it comes to paying attention to detail, you have a more challenging time focusing on just one thing. Your mind tends to wander, and you daydream or wonder where the time went. It makes it hard to stay focused on your studies or your job.
 <br/><br/>
You tend to be judgemental and critical of other people's actions, beliefs, and choices. You're quick to think that other people are foolish for making certain decisions or exploring certain hobbies. You rarely put the time or effort into finding out if what you're being told is true. You take other people's words for it and go along with it.
 <br/><br/>
 You are superficial and do not prefer to get attached, even in a personal relationship, but it is not intentional.
 <br/><br/>
 <p className="playfair text-black text-xl md:text-3xl">Manipulator</p><br/>
You tend to be very complimentary of other people. You will often say nice things to others when you need to compliment them. You're great at being fake when it comes to being nice to others, particularly when it comes to their looks. You'll salute them outside and whisper your true feelings about them on the backchannel.<br/><br/>
You love a good power struggle, particularly one that can be played out publicly. You often find yourself in arguments with others that can be solved with a simple "Yes, dear." or "Of course, dear." To get what you want, you will often manipulate situations to make others feel like they have no choice but to do what you want.
 <br/><br/>
 
 
 <p className="playfair text-black text-xl md:text-3xl">Lack of direction</p><br/>
Gemini individuals are inconsistent. Your life is a series of contradictions, and you feel as if you are at the center of a chaotic storm. Your emotions are all over the place, and you can't seem to make sense of anything, least of all your feelings. You have no idea who you are anymore.<br/><br/>
 It's a never-ending cycle of second-guessing yourself and feeling like you're going crazy. You think there must be something wrong with you when all you can think about is how this affects your life. You're so consumed by your mental state that you can't help but feel like your entire world is caving in on you.
 <br/><br/>
However, it would be best if you also did something that interests you to keep you motivated. If you are constantly doing the same thing, you get bored and want to try something new.
 <br/><br/>
 
 <p className="playfair text-black text-xl md:text-3xl">Lack of seriousness</p><br/>
You are a profoundly optimistic person and enjoy being in the present moment as much as possible. You tend to ignore your responsibilities and do not like to be told what to do. You are very fickle-minded and ready to throw tantrums when you feel restless or find difficulty staying rooted in one thing. This makes it difficult for you to plan and achieve goals.
 <br/><br/>
 <p className="playfair text-black text-xl md:text-3xl">Anxious</p><br/>
You get over-anxious when something doesn't go the way you planned or receive negative feedback. You tend to exhibit this behavior when you are in a new environment or with new people.<br/><br/>
Your energies are imbalanced, and you keep your stress and emotions inside you, which makes you feel anxious. You feel a constant tension in your body and mind, making you feel completely drained of energy.
 <br/><br/>
You tend to keep your emotions and stress inside you, making you feel anxious. You feel a constant tension in your body and mind, making you feel completely drained of energy. You tend to withdraw yourself from your loved ones and don't share your feelings. You start up with many projects due to your "can do" attitude but are not able to follow through.
<br/><br/>
              
              
            </span>
          </div>
          <div className="container mx-auto">
            <div className="flex justify-between mb-4 mx-2">
              <h1 className="text-xl md:text-3xl text-black playfair">
                Recent Blogs
              </h1>
              <a
                className="text-sm md:text-md text-orange-500 text-center font-semibold"
                href="https://www.bodhi.app/blog/"
              >
                See More
              </a>
            </div>
            <div className="relative">
                <div className="absolute top-0 right-0 w-0 md:w-36 fixed h-full bg-gradient-to-r from-transparent to-white"></div>
              <div className="flex overflow-x-scroll pb-4 mt-2 hide-scroll-bar scrollbar-hide">
                <div className="flex flex-nowrap ml-2">
                  {blogs.slice(0, 10).map((item, i) => {
                    return (
                      <a href={item.link} key={i} target="_blank">
                        <div className="flex flex-col h-80 w-52 justify-start items-start bg-gray-100 mr-10 mb-10 rounded-lg overflow-hidden hover:shadow-lg">
                          <img
                            className="object-cover w-52 h-52 w-auto bg-gray-100"
                            src={
                              item?.yoast_head_json?.og_image
                                ? item?.yoast_head_json?.og_image[0]?.url
                                : ""
                            }
                          />
                          <span className="mt-2 block mx-2 text-center">
                            {item?.yoast_head_json?.title ?? ""}
                          </span>
                        </div>
                      </a>
                    );
                  })}
                  {/* <div className="flex h-80 w-52 justify-center items-center "></div> */}
                </div>
              </div>
            </div>
          <button
            className="justify-center text-white fixed right-2 bottom-2 rounded-t-2xl but px-4 py-2 flex gap-1 shadow-xl"
            onClick={() => {
              navigate("/consultAstro");
            }}
          >
            {user.name !== undefined?`Consult Top Astrologers Now!`:`Login & get ${
              user?.currencyType == "usd" ? "$ 4" : "₹ 91"
            } in your account!`}
          </button>
          </div>
        </main>
        <Footer />
      </div>
    </div>
  );
}

export default Index;
